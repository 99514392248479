import React, { lazy, Suspense } from "react";

// Lazy load all components
const HomePage = lazy(() => import("../components/HomePage"));
const BlogPage = lazy(() => import("../components/blog/BlogPage"));
const BlogArticle = lazy(() => import("../components/blog/BlogArticle"));
const SurveyForm = lazy(() => import("../components/SurveyTaking/SurveyForm"));
const SurveyPreview = lazy(() => import("../components/SurveyPreview"));
const SendSurvey = lazy(() => import("../components/UserInfo"));
const SurveySent = lazy(() => import("../components/SurveySent"));
// const PaymentPage = lazy(() => import("../components/payment/PaymentPage"));
// const PostPaymentPage = lazy(() => import("../components/payment/PostPaymentPage"));
const SignUp = lazy(() => import('../components/SignUp'));
const Dashboard = lazy(() => import('../components/Dashboard'));
const Profile = lazy(() => import('../components/Profile'));
const Login = lazy(() => import("../components/Login"));
const ReportPage = lazy(() => import("../components/ReportPage"));
const SharedReport = lazy(() => import("../components/SharedReport"));
const Unsubscribe = lazy(() => import("../components/Unsubscribe"));
const FAQ = lazy(() => import('../components/faq'));
const PrivacyPolicy = lazy(() => import('../components/PrivacyPolicy'));
const RecipientInfo = lazy(() => import('../components/RecipientInfo'));
const HowItWorks = lazy(() => import("../components/HowItWorks"));
const SurveyCategories = lazy(() => import("../components/SurveyCategories"));
const CouplesSurveyPreview = lazy(() => import("../components/CouplesSurveyPreview"));
const CouplesSurveySend = lazy(() => import("../components/CouplesSurveySend"));
const SocialSurveyPreview = lazy(() => import("../components/SocialSurveyPreview"));
const TermsAndConditions = lazy(() => import("../components/TermsAndConditions"));

export const ROUTES = [
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path: "/home",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <TermsAndConditions />
      </Suspense>
    ),
  },
  {
    path: "/blog",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <BlogPage />
      </Suspense>
    ),
  },
  {
    path: "/blog/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <BlogArticle />
      </Suspense>
    ),
  },
  {
    path: "/survey/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SurveyForm />
      </Suspense>
    ),
  },
  {
    path: "/surveys/:category",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SurveyCategories />
      </Suspense>
    ),
  },
  // {
  //   path: "/payment",
  //   element: (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <PaymentPage />
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: "/post-payment",
  //   element: (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <PostPaymentPage />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/surveys/Preview/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SurveyPreview />
      </Suspense>
    ),
  },
  {
    path: "/surveys/couples/preview",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CouplesSurveyPreview />
      </Suspense>
    ),
  },
  {
    path: "/surveys/couples/send",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CouplesSurveySend />
      </Suspense>
    ),
  },
  {
    path: "/surveys/social/preview",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SocialSurveyPreview />
      </Suspense>
    ),
  },
  {
    path: "/sendSurvey/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SendSurvey />
      </Suspense>
    ),
  },
  {
    path: "/sendSurvey/:id/recipients",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <RecipientInfo />
      </Suspense>
    ),
  },
  {
    path: "/surveySent",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SurveySent />
      </Suspense>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    path: "/SignUp",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SignUp />
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/Profile",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Profile />
      </Suspense>
    ),
  },

  {
    path: "/reports/:reportId",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ReportPage />
      </Suspense>
    ),
  },

  {
    path: "/sharedReport/:reportId",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SharedReport />
      </Suspense>
    ),
  },
  {
    path: "/unsubscribe",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Unsubscribe />
      </Suspense>
    ),
  },
  {
    path: "/faq",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <FAQ />
      </Suspense>
    ),
  },
  {
    path: "/privacy",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: "/howitworks",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HowItWorks />
      </Suspense>
    ),
  },
];